//
// map.js
//

const maps = document.querySelectorAll('[data-map]');
const accessToken = 'pk.eyJ1IjoiZ3BpY2F6byIsImEiOiJja3lkcm9zdXQwMWV0MndzMjVqbnltbDU1In0.Bsetr3iaZ9V4_4GSPZJWiA';

maps.forEach((map) => {
  const elementOptions = map.dataset.map;

  const defaultOptions = {
    container: map,
    style: 'mapbox://styles/mapbox/light-v9',
    scrollZoom: false,
    interactive: false,
  };

  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  // Get access token
  mapboxgl.accessToken = accessToken;

  // Init map
  new mapboxgl.Map(options);
});
